<template>
  <router-view />
</template>

<script>
import reportsModule from '../../store/reports'

export default {
  beforeCreate () {
    this.$store.registerModule('reports', reportsModule)
  },
  destroyed () {
    this.$store.unregisterModule('reports')
  }
}
</script>
