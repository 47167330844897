export const REPORTS_BATCH_DELETE_REQUEST = 'REPORTS_BATCH_DELETE_REQUEST'
export const REPORTS_BATCH_DELETE_SUCCESS = 'REPORTS_BATCH_DELETE_SUCCESS'
export const REPORTS_BATCH_DELETE_FAILURE = 'REPORTS_BATCH_DELETE_FAILURE'

export const REPORTS_FETCH_MANY_REQUEST = 'REPORTS_FETCH_MANY_REQUEST'
export const REPORTS_FETCH_MANY_SUCCESS = 'REPORTS_FETCH_MANY_SUCCESS'
export const REPORTS_FETCH_MANY_FAILURE = 'REPORTS_FETCH_MANY_FAILURE'

export const REPORTS_FETCH_REQUEST = 'REPORTS_FETCH_REQUEST'
export const REPORTS_FETCH_SUCCESS = 'REPORTS_FETCH_SUCCESS'
export const REPORTS_FETCH_FAILURE = 'REPORTS_FETCH_FAILURE'

export const REPORTS_SAVE_REQUEST = 'REPORTS_SAVE_REQUEST'
export const REPORTS_SAVE_SUCCESS = 'REPORTS_SAVE_SUCCESS'
export const REPORTS_SAVE_FAILURE = 'REPORTS_SAVE_FAILURE'

export const EMPTY_REPORTS = 'EMPTY_REPORTS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'