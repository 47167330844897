import {
  REPORTS_BATCH_DELETE_REQUEST,
  REPORTS_BATCH_DELETE_SUCCESS,
  REPORTS_BATCH_DELETE_FAILURE,
  REPORTS_FETCH_MANY_REQUEST,
  REPORTS_FETCH_MANY_SUCCESS,
  REPORTS_FETCH_MANY_FAILURE,
  REPORTS_FETCH_REQUEST,
  REPORTS_FETCH_SUCCESS,
  REPORTS_FETCH_FAILURE,
  REPORTS_SAVE_REQUEST,
  REPORTS_SAVE_SUCCESS,
  REPORTS_SAVE_FAILURE,

  EMPTY_REPORTS,
  CLEAR_ERRORS
} from './types'

export default {

  // Postcode Reports

  fetchPostcodeReport ({ commit }) {
    commit(REPORTS_FETCH_REQUEST)
    return window.axios.get(`/reports/postcode`).then(response => {
      commit(REPORTS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(REPORTS_FETCH_FAILURE, error)
      throw error
    })
  },

  fetchTabeoReport ({ commit }, { from, to }) {
    commit(REPORTS_FETCH_REQUEST)
    return window.axios.get(`/reports/tabeo`, { params: { from, to } }).then(response => {
      commit(REPORTS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(REPORTS_FETCH_FAILURE, error)
      throw error
    })
  },

}
